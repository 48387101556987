<template>
    <div class="dashboard bookings costSaleServices tariff-nonacc">
        <div class="head">
            <div class="title">
                <span>{{  title }}</span>
                <vs-button color="warning"  @click="reset()" type="filled" icon="backspace" v-if="this.rates.length > 0"></vs-button>
            </div>
        </div>
        
        <div class="search-booking" v-if="this.rates.length < 1">
            <div class="item">
                <label for="">Fechas</label>
                <input-date-picker v-model="date" :currentDate="date"></input-date-picker>                
            </div>
            <div class="item">
               <search-supplier @supplier="findSupplier" :keepSearch="true" v-click-outside="closeSuggest" :openSuggestions="openSuggestions"></search-supplier>
            </div>
            <div class="item">
                <vs-button color="primary"  @click="search()" type="filled" icon="search" :disabled="!this.reference != ''"></vs-button>
            </div>
        </div>

        <div class="services" v-if="this.rates.length > 0">
            
            <div class="booking-info" v-if="this.rates.length > 0">
                <div class="item">
                    <span>Código</span>
                    {{ suppCode }}
                </div>
                <div class="item">
                    <span>Proveedor</span>
                    {{ suppName }}
                </div>
            </div>

            <div class="title book-title">Tarifas</div>

            <div class="bookings-wrapper">
                <table-rates :items="rates"></table-rates>
                
                <pagination :total="pages" @handlePagination="handlePagination" :key="key"/>

            </div>

        </div>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>
        
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import pagination from '@/components/Pagination.vue'
// import notResults from '@/components/NotResults.vue'
import searchSupplier from '@/components/findSupplier.vue'
import tableRates from '@/components/Tables/Tariff/NonAcc.vue'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

export default {
    name: "TariffNonAccView",
    components: {
        modal,
        // notResults,
        searchSupplier,
        inputDatePicker,
        tableRates,
        pagination,
    },
    data () {
        return {
            key: 1,
            date: "",
            reference: "",
            rates: [],
            suppCode: "",
            suppName: "",
            totalReg: 0,
            paged: 1,
            pages: 0,
            suppliers: [],
            openSuggestions: true,

            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            totalCost: 0,
            totalTax: 0,
            totalSell: 0,
            marginUsd: 0,
            bookingReference: "",
            bookingName: "",
            bookingAgent: "",
            bookingVendor: "",
            title: "Tariff Non Accommodation"
            
        }
    },    
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                console.log(el)
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async servicesByBooking(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.getTariffNonAcc({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
                "supplier" : this.reference,
                "pages" : this.pages,
                "paged" : this.paged,
            });            
            if((response.status/100) == 2){
                this.rates  = response.data.items;
                this.totalReg = response.data.total;
                this.pages    = response.data.pages;
                
                if(this.rates.length > 0){
                    this.suppCode   = this.rates[0].SUPPLIERCODE;
                    this.suppName  = this.rates[0].SUPPLIERNAME;
                    this.title = 'Proveedor - ' + this.suppCode;
                }
            }            
            this.LOADING_STATUS(false);
        },
        handlePagination(_paged) {
            
            this.paged = _paged    
            this.servicesByBooking()
        },
        search() {
            this.servicesByBooking()
        },
        reset() {
            // this.reference = "";
            this.rates  = [];
            this.title = "Tariff Non Accommodation";
            this.totalReg = 0;
            this.paged = 1;
            this.pages = 0;
        },
        findSupplier(response) {
           this.reference = response.CODE;
        },
        closeSuggest(){
            this.openSuggestions = !this.openSuggestions
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
    }
}
</script>