<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="SERVICECODE">Service</vs-th>
                    <vs-th sort-key="LOCCODE">Location</vs-th>
                    <vs-th sort-key="OPTIONDESCRIPTION">Option_Description</vs-th>
                    <vs-th sort-key="PRICE_CODE">PCODE</vs-th>
                    <vs-th sort-key="STAY_TYPE">STAY_TYPE</vs-th>
                    <vs-th sort-key="RATE_TEXT">RATE_TEXT</vs-th>
                    <vs-th sort-key="DATE_FROM">Date_From</vs-th>
                    <vs-th sort-key="DATE_TO">Date_To</vs-th>
                    <vs-th sort-key="RATEST">Status</vs-th>
                    <vs-th sort-key="SERVITEM">SERVITEM</vs-th>
                    <vs-th sort-key="COSTGROUPS">Group_Cost</vs-th>
                    <vs-th sort-key="SELLGROUPS">Group_Sell</vs-th>
                    <vs-th sort-key="COSTFITS">FIT's_Cost</vs-th>
                    <vs-th sort-key="SELLFITS">FIT's_Sell</vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].SERVICECODE"> 
                                {{ data[indextr].SERVICECODE}}
                            </vs-td>                           
                            <vs-td :data="data[indextr].LOCCODE"> 
                                {{ data[indextr].LOCCODE}}
                            </vs-td>                           
                            <vs-td :data="data[indextr].OPTIONDESCRIPTION"> 
                                {{ data[indextr].OPTIONDESCRIPTION}}
                            </vs-td>                           
                            <vs-td :data="data[indextr].PRICE_CODE"> 
                                {{ data[indextr].PRICE_CODE}}
                            </vs-td>                           
                            <vs-td :data="data[indextr].STAY_TYPE"> 
                                {{ data[indextr].STAY_TYPE}}
                            </vs-td>                           
                            <vs-td :data="data[indextr].RATE_TEXT"> 
                                {{ data[indextr].RATE_TEXT}}
                            </vs-td>
                            <vs-td :data="data[indextr].DATE_FROM"> 
                                {{ data[indextr].DATE_FROM | moment('timezone', 'Etc/GMT', 'DD.MM.YYYY')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].DATE_TO"> 
                                {{ data[indextr].DATE_TO | moment('timezone', 'Etc/GMT', 'DD.MM.YYYY')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].RATEST"> 
                                {{ data[indextr].RATEST}}
                            </vs-td>                           
                            <vs-td :data="data[indextr].SERVITEM"> 
                                {{ data[indextr].SERVITEM }}
                            </vs-td>         
                            <vs-td :data="data[indextr].COSTGROUPS" class="pending"> 
                                {{ $n(data[indextr].COSTGROUPS, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].SELLGROUPS" class="pending"> 
                                {{ $n(data[indextr].SELLGROUPS, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].COSTFITS" class="success"> 
                                {{ $n(data[indextr].COSTFITS, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].SELLFITS" class="success"> 
                                {{ $n(data[indextr].SELLFITS, 'currency') }} 
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableServicesByBooking",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
            balance: 0,
            invTotal: 0,
            rcTotal:  0
        }
    },
    watch: {
        items(val){
            this.bookings = val
            
        },
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        }
    }
}
</script>